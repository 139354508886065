/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "SF UI Text";
  font-style: normal;
  font-weight: 400;
  src: local("SF UI Text Regular"), url("SFUIText-Regular.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text";
  font-style: normal;
  font-weight: 500;
  src: local("SF UI Text Medium"), url("SFUIText-Medium.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text";
  font-style: normal;
  font-weight: 600;
  src: local("SF UI Text Semibold"),
    url("SFUIText-Semibold.woff") format("woff");
}


@font-face {
  font-family: "SF UI Text";
  font-style: normal;
  font-weight: 700;
  src: local("SF UI Text Bold"), url("SFUIText-Bold.woff") format("woff");
}

@font-face {
  font-family: "SF UI Text";
  font-style: normal;
  font-weight: 800;
  src: local("SF UI Text Heavy"), url("SFUIText-Heavy.woff") format("woff");
}
