:root {
  --primary-color: #007bff;
  --gradient-color: linear-gradient(to right, #fb8f66, #7f3cef);
  --orange-color: #fb8f66;
  --purple-color: #7f3cef;
}

:export {
  primaryColor: var(--primary-color);
  gradientColor: var(--gradient-color);
}
